import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Seo from '../components/Seo';
import { Card } from '../components/Card'
import { SimpleLayout } from '../components/SimpleLayout'

const LinkIcon = (props) => {
  return (
    <svg viewBox="0 0 24 24" aria-hidden="true" {...props}>
      <path
        d="M15.712 11.823a.75.75 0 1 0 1.06 1.06l-1.06-1.06Zm-4.95 1.768a.75.75 0 0 0 1.06-1.06l-1.06 1.06Zm-2.475-1.414a.75.75 0 1 0-1.06-1.06l1.06 1.06Zm4.95-1.768a.75.75 0 1 0-1.06 1.06l1.06-1.06Zm3.359.53-.884.884 1.06 1.06.885-.883-1.061-1.06Zm-4.95-2.12 1.414-1.415L12 6.344l-1.415 1.413 1.061 1.061Zm0 3.535a2.5 2.5 0 0 1 0-3.536l-1.06-1.06a4 4 0 0 0 0 5.656l1.06-1.06Zm4.95-4.95a2.5 2.5 0 0 1 0 3.535L17.656 12a4 4 0 0 0 0-5.657l-1.06 1.06Zm1.06-1.06a4 4 0 0 0-5.656 0l1.06 1.06a2.5 2.5 0 0 1 3.536 0l1.06-1.06Zm-7.07 7.07.176.177 1.06-1.06-.176-.177-1.06 1.06Zm-3.183-.353.884-.884-1.06-1.06-.884.883 1.06 1.06Zm4.95 2.121-1.414 1.414 1.06 1.06 1.415-1.413-1.06-1.061Zm0-3.536a2.5 2.5 0 0 1 0 3.536l1.06 1.06a4 4 0 0 0 0-5.656l-1.06 1.06Zm-4.95 4.95a2.5 2.5 0 0 1 0-3.535L6.344 12a4 4 0 0 0 0 5.656l1.06-1.06Zm-1.06 1.06a4 4 0 0 0 5.657 0l-1.061-1.06a2.5 2.5 0 0 1-3.535 0l-1.061 1.06Zm7.07-7.07-.176-.177-1.06 1.06.176.178 1.06-1.061Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const Head = ({data}) => (
  <Seo 
    title={`ShortBear - Coding tips, tricks and FAQs.`} 
    description={`Learn how to code while keeping your sanity.`}
  />
)

const HomePage = ({ data }) => {
  const {categories} = data;
  return (
    <>
      <SimpleLayout
        title={`"Learning to code makes me feel so dumb!"`}
        intro="Getting stuck when coding will make you want to take up day drinking. Trust me, you're not alone. That's why I compiled a list of common questions to help you in your journey. Just choose what language you're working on from the links below."
      >
        <ul
          className="grid grid-cols-1 gap-x-12 gap-y-16 sm:grid-cols-2 lg:grid-cols-3"
        >
          {categories.nodes.map(({frontmatter: category}) => (
            <Card as="li" key={category.title}>
              <div className="relative z-10 flex h-12 w-12 items-center justify-center rounded-full bg-white shadow-md shadow-zinc-800/5 ring-1 ring-zinc-900/5 dark:border dark:border-zinc-700/50 dark:bg-zinc-800 dark:ring-0">
                <GatsbyImage 
                    image={getImage(category.logo)}
                    alt={`${category.title} Logo`}
                    className='h-8 w-8'
                  />
              </div>
              <h2 className="mt-6 text-base font-semibold text-zinc-800 dark:text-zinc-100">
                <Card.Link href={`/${category.slug}`}>{category.title}</Card.Link>
              </h2>
              <Card.Description>{category.excerpt}</Card.Description>
              <p className="relative z-10 mt-6 flex text-sm font-medium text-zinc-400 transition group-hover:text-teal-500 dark:text-zinc-200">
                <LinkIcon className="h-6 w-6 flex-none" />
                <span className="ml-2">{`Read more...`}</span>
              </p>
            </Card>
          ))}
        </ul>
      </SimpleLayout>
    </>
  )
}

export default HomePage;

export const pageQuery = graphql`
query AllCategories {
  categories: allMarkdownRemark(
    filter: {frontmatter: {templateKey: {eq: "category"}}}
  ) {
    nodes {
      frontmatter {
        title
        slug
        excerpt
        logo {
          childImageSharp {
            gatsbyImageData(width: 40)
          }
        }
      }
    }
  }
}
`
